import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { id: "app" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("header", null, [
      _cache[1] || (_cache[1] = _createElementVNode("h1", null, "Everest App", -1)),
      (_ctx.isAuthenticated)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.logout && _ctx.logout(...args)))
          }, "Logout"))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("main", null, [
      _createVNode(_component_router_view)
    ]),
    _cache[2] || (_cache[2] = _createElementVNode("footer", null, [
      _createElementVNode("p", null, "© 2025 Everest App")
    ], -1))
  ]))
}