<template>
  <div class="status">
    <h2>Database Connection Status</h2>
    <p>Status: {{ status }}</p>
    <button @click="fetchStatus">Refresh Status</button>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from "vue";
import { checkDbStatus } from "../api/api";

export default defineComponent({
  name: "StatusView",
  setup() {
    const status = ref("Checking...");

    const fetchStatus = async () => {
      try {
        status.value = await checkDbStatus();
      } catch (e) {
        status.value = "Error fetching status";
      }
    };

    onMounted(fetchStatus);

    return { status, fetchStatus };
  },
});
</script>