<template>
  <div id="app">
    <!-- Header with a conditional logout button based on auth state -->
    <header>
      <h1>Everest App</h1>
      <button v-if="isAuthenticated" @click="logout">Logout</button>
    </header>

    <!-- Main content area where different views will be rendered -->
    <main>
      <router-view />
    </main>

    <!-- Footer -->
    <footer>
      <p>&copy; 2025 Everest App</p>
    </footer>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "App",
  setup() {
    const store = useStore();
    const router = useRouter();

    // Computed property to get the auth state from Vuex
    const isAuthenticated = computed(() => store.getters.isAuthenticated);

    // Logout method that commits the logout action and redirects to login
    const logout = () => {
      store.dispatch("logout");
      router.push("/login");
    };

    return {
      isAuthenticated,
      logout,
    };
  },
});
</script>

<style scoped>
/* Basic styles for layout and elements */
#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: #42b883;
  color: white;
}

main {
  flex: 1;
  padding: 1rem;
}

footer {
  text-align: center;
  padding: 1rem;
  background-color: #333;
  color: white;
}
</style>
