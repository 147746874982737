<template>
  <div class="login">
    <h2>Login</h2>
    <form @submit.prevent="handleLogin">
      <div>
        <label>Username:</label>
        <input type="text" v-model="username" required />
      </div>
      <div>
        <label>Password:</label>
        <input type="password" v-model="password" required />
      </div>
      <button type="submit">Login</button>
      <p v-if="error">{{ error }}</p>
    </form>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

export default defineComponent({
  name: "LoginView",
  setup() {
    const username = ref("");
    const password = ref("");
    const error = ref("");
    const router = useRouter();
    const store = useStore();

    const handleLogin = async () => {
      try {
        await store.dispatch("login", { username: username.value, password: password.value });
        router.push("/status");
      } catch (e) {
        error.value = "Invalid credentials";
      }
    };

    return { username, password, error, handleLogin };
  },
});
</script>