import axios from "axios";

const api = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
});

export async function login(username: string, password: string) {
  const response = await api.post("/login", { username, password });
  return response.data.access_token;
}

export async function checkDbStatus() {
  const token = localStorage.getItem("token");
  const response = await api.get("/check_db", {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data.status;
}
