import { createStore } from 'vuex';
import { login as apiLogin } from "../api/api";

export default createStore({
  state: {
    token: localStorage.getItem("token") || null,
  },
  getters: {
    isAuthenticated: (state) => !!state.token,
  },
  mutations: {
    setToken(state, token) {
      state.token = token;
      localStorage.setItem("token", token);
    },
    clearToken(state) {
      state.token = null;
      localStorage.removeItem("token");
    },
  },
  actions: {
    async login({ commit }, { username, password }) {
      const token = await apiLogin(username, password);
      commit("setToken", token);
    },
    logout({ commit }) {
      commit("clearToken");
    },
  },
  modules: {
  },
});
