import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "login" }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[6] || (_cache[6] = _createElementVNode("h2", null, "Login", -1)),
    _createElementVNode("form", {
      onSubmit: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleLogin && _ctx.handleLogin(...args)), ["prevent"]))
    }, [
      _createElementVNode("div", null, [
        _cache[3] || (_cache[3] = _createElementVNode("label", null, "Username:", -1)),
        _withDirectives(_createElementVNode("input", {
          type: "text",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.username) = $event)),
          required: ""
        }, null, 512), [
          [_vModelText, _ctx.username]
        ])
      ]),
      _createElementVNode("div", null, [
        _cache[4] || (_cache[4] = _createElementVNode("label", null, "Password:", -1)),
        _withDirectives(_createElementVNode("input", {
          type: "password",
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.password) = $event)),
          required: ""
        }, null, 512), [
          [_vModelText, _ctx.password]
        ])
      ]),
      _cache[5] || (_cache[5] = _createElementVNode("button", { type: "submit" }, "Login", -1)),
      (_ctx.error)
        ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.error), 1))
        : _createCommentVNode("", true)
    ], 32)
  ]))
}